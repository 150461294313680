<style lang="scss">
.enterprise-details-card {
  &__items {
    b {
      display: block;
    }
  }
}
</style>
<!--eslint-disable-->
<template>
  <div class="enterpise-apps-page" v-if="!loading">

    <b-card no-body class="enterprise-details-card">
      <div class="d-flex align-items-baseline border-bottom">
        <h3 class="font-weight-bolder m-2">{{ enterprise.display_name }}</h3>
        <div class="ui-spacer"></div>
        <b-button @click="switchEnterprise()" class="d-flex ml-auto btn-outline mr-2" variant="success">Switch</b-button>
        <b-btn v-if="$can('Manage', 'All')"
               :to="{ name: 'enterprise-edit', params: { uid: enterprise.uid }}"
               variant="primary"
               class="mr-2">Update
        </b-btn>
      </div>

      <section class="m-2 mt-0 enterprise-details-card__items row">
        <section class="col-4">
          <p class="">Contact Email: <b>{{enterprise.contact_email}}</b></p>
          <p class="">Password Requirements: <b>{{enterprise.password_quality}}</b></p>
          <p class="">Two Factor Enabled: <b>{{enterprise.two_factor_enabled}}</b></p>
          <p class="">Purple Account Enabled: <b>{{enterprise.purple_account_enabled}}</b></p>
          <p class="">Purple Wallet Enabled: <b>{{enterprise.purple_wallet_enabled}}</b></p>
          <p class="">Purple Play Video Access: <b>{{enterprise.purple_play_video_access | capEachWord}}</b></p>
          <p class="">Owner Device Limit: <b>{{enterprise.owner_device_limit === 0 ? "Unlimited" : enterprise.owner_device_limit}}</b></p>
          <p>Possession Request Message: <b>{{enterprise.possession_request_message}}</b></p>
        </section>
        <section class="col-sm-4">
          <h4 class="font-weight-bolder mb-2">Data Protection Officer</h4>
          <p class="">Name: <b>{{enterprise.data_protection_officer_name || '-'}}</b></p>
          <p class="">Email: <b>{{enterprise.data_protection_officer_email || '-'}}</b></p>
          <p class="mb-0">Phone: <b>{{enterprise.data_protection_officer_phone || '-'}}</b></p>
        </section>

        <section class="col-sm-4">
          <h4 class="font-weight-bolder mb-2">EU Representative</h4>
          <p class="">Name: <b>{{enterprise.eu_representative_name || '-'}}</b></p>
          <p class="">Email: <b>{{enterprise.eu_representative_email || '-'}}</b></p>
          <p class="mb-0">Phone: <b>{{enterprise.eu_representative_phone || '-'}}</b></p>
        </section>
      </section>
    </b-card>

    <!-- Enterprise proxy -->
    <b-card no-body>
      <div class="d-flex align-items-baseline border-bottom">
        <h3 class="font-weight-bolder m-2">Proxy</h3>
        <div class="ui-spacer"></div>
      </div>

      <section v-if="!loadingEnterpriseProxy" class="m-2 mt-0 enterprise-details-card__items row">
        <section v-if="enterprise_proxy !== null" class="col-12">
          <p>Enabled:
            <b v-if="enterprise_proxy.enabled">Yes</b>
            <b v-else>No</b>
          </p>
          <p>Allowed IP Addresses:
            <b v-if="enterprise_proxy.allowed_ip_addresses === ''">-</b>
            <b v-else>{{enterprise_proxy.allowed_ip_addresses}}</b>
          </p>
        </section>
      </section>
    </b-card>

    <!-- Scribe Data Retention -->
    <b-card no-body>
      <div class="d-flex align-items-baseline border-bottom">
        <h3 class="font-weight-bolder m-2">Scribe Data Retention</h3>
        <div class="ui-spacer"></div>
      </div>

      <section class="m-2 mt-0 enterprise-details-card__items row">
        <section class="col-12">
          <p>Audio Files:
            <b> {{ enterprise.scribe_audio_retention_days }} Days</b>
          </p>
          <p>Transcripts:
            <b> {{ enterprise.scribe_transcript_retention_days }} Days</b>
          </p>
          <p>Summaries:
            <b> {{ enterprise.scribe_summary_retention_days }} Days</b>
          </p>
        </section>
      </section>
    </b-card>

    <!-- Webhooks -->
    <b-card no-body>
      <div class="d-flex align-items-baseline border-bottom">
        <h3 class="font-weight-bolder m-2">Webhooks</h3>
        <div class="ui-spacer"></div>
      </div>

      <section v-if="!loadingWebhooks" class="m-2 mt-0 enterprise-details-card__items row">
        <!-- Webhook exists -->
        <section v-if="webhook !== null" class="col-12">
          <p>Method: <b>POST</b></p>
          <p>URL: <b>{{webhook.url}}</b></p>
          <p v-if="webhook.secret">Secret: <b>{{webhook.secret}}</b></p>

          <div class="d-flex mt-2">
            <b-button
              @click="showModal('modal-delete-webhook')"
              class="btn btn-danger mr-2">
              Delete Webhook
            </b-button>
            <b-button
              @click="showModal('modal-update-webhook')"
              class="btn btn-success">
              Update Webhook
            </b-button>
          </div>
        </section>

        <!-- No webhook exists -->
        <section v-else class="col-4">
          <p>Click below to create a webhook for the enterprise. Once created the secret key for the endpoint will be visible. <b>NOTE: The secret is only available immediately after creation. Make sure to note in down somewhere safe.</b></p>
          <b-button
            @click="showModal('modal-create-webhook')"
            class="btn btn-success">
            Create Webhook
          </b-button>
        </section>
      </section>
    </b-card>

    <!-- Applications -->
    <b-card no-body>
      <enterprise-applications :uid="enterprise.uid"></enterprise-applications>
    </b-card>

    <!-- Proxy exclude lists -->
    <b-card no-body>
      <enterprise-proxy-exclude-lists :uid="enterprise.uid"></enterprise-proxy-exclude-lists>
    </b-card>

    <!-- Modals -->
    <b-modal id="modal-create-webhook" title="Create Webhook" hide-footer no-close-on-backdrop>
      <CreateWebhookModal
        @create="createWebhook"
        @close="closeModals"
      />
    </b-modal>

    <b-modal id="modal-update-webhook" title="Update Webhook" hide-footer no-close-on-backdrop>
      <UpdateWebhookModal
        v-if="!!webhook"
        :webhook="webhook"
        @update="updateWebhook"
        @close="closeModals"
      />
    </b-modal>

    <b-modal id="modal-delete-webhook" title="Delete Webhook" hide-footer>
      <delete-modal
        v-if="!!webhook"
        @close="closeModals"
        @delete="deleteWebhook"
        title="Are you sure you wish to delete this webhook?"
        subtitle="This will stop all events being sent to this endpoint"
      />
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import { mapState } from 'vuex'
import auth from '@/auth/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EnterpriseApplications from '@/views/enterprises/viewEnterprises/sections/EnterpriseApplications.vue'
import WebhookService from "@/services/WebhookService";
import CreateWebhookModal from "@/views/enterprises/viewEnterprises/sections/CreateWebhookModal.vue";
import UpdateWebhookModal from "@/views/enterprises/viewEnterprises/sections/UpdateWebhookModal.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import helperService from "@/services/HelperService";
import EnterpriseService from "@/services/EnterpriseService";
import EnterpriseProxyExcludeLists from "@/views/enterprises/viewEnterprises/sections/EnterpriseProxyExcludeLists.vue";

export default {
  name: 'EnterpriseApps',
  components: {
    DeleteModal,
    UpdateWebhookModal,
    CreateWebhookModal,
    EnterpriseApplications,
    EnterpriseProxyExcludeLists,
  },
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      loadingWebhooks: true,
      loadingEnterpriseProxy: true,
      webhook: null,
      enterprise_proxy: null
    }
  },
  computed: {
    ...mapState({
      enterprise: state => state.enterprise.enterprise,
    }),
  },
  mounted() {
    this.getEnterprise()
    this.getWebhook()
    this.getEnterpriseProxy()
  },
  methods: {
    switchEnterprise() {
      auth.switch({
        enterprise_uid: this.uid,
      })
        .then(async response => {
          auth.setToken(response.data.accessToken)
          // Response must be authorised so we should be able to use the refresh token to fetch the user details
          await auth.refreshCurrentUser()

          // Make sure they have some permissions
          if (auth.getCurrentUser().permissions == null) {
            this.loginError = 'You do not have the right Role or Permissions. Please contact your Site Administrator'
            return
          }

          this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Switch Successful',
                icon: 'HardDriveIcon',
                variant: 'success',
                text: 'You have now switched enterprises',
              },
            },
            {
              timeout: 2000,
              position: 'top-center',
            })
        }, error => {
          let message
          if (error.error) {
            message = error.error
          } else {
            message = error
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Switch Failed',
              icon: 'HardDriveIcon',
              variant: 'danger',
              text: `The switch failed. Please try again. ${message}`,
            },
          })
        })
    },
    async getEnterprise() {
      this.loading = true
      await this.$store.dispatch('enterprise/getEnterprise', this.uid)
      this.loading = false
    },
    async getWebhook() {
      this.loadingWebhooks = true
      try {
        const res = await WebhookService.get(this.uid)
        this.webhook = res.data
      } catch(_) {
      } finally {
        this.loadingWebhooks = false
      }
    },
    async createWebhook({url}) {
      this.closeModals()
      const dto = {
        url: url,
        enterprise_uid: this.uid,
      }
      try {
        const res = await WebhookService.create(dto)
        this.webhook = res.data;
        helperService.showNotfySuccess(this.$toast, 'Webhook created!')
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Error creating webhook, please refresh and try again')
      }
    },
    async updateWebhook({url}) {
      this.closeModals()
      const dto = {
        url: url,
      }
      try {
        const res = await WebhookService.update(this.webhook.id, dto)
        this.webhook.url = res.data.url
        helperService.showNotfySuccess(this.$toast, 'Webhook updated!')
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Error updating webhook, please refresh and try again')
      }
    },
    async deleteWebhook() {
      this.closeModals()
      try {
        await WebhookService.delete(this.webhook.id)
        this.webhook = null
        helperService.showNotfySuccess(this.$toast, 'Webhook deleted!')
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'Error deleting webhook, please refresh and try again')
      }
    },
    async getEnterpriseProxy() {
      this.loadingEnterpriseProxy = true
      try {
        const res = await EnterpriseService.getEnterpriseProxy(this.uid)
        this.enterprise_proxy = res.data
      } catch(_) {
      } finally {
        this.loadingEnterpriseProxy = false
      }
    },
    async refreshApps() {
      this.closeModals();
      await this.$store.dispatch('enterprise/getApps', this.uid);
    },
    showModal(name) {
      this.$bvModal.show(name)
    },
    closeModals() {
      this.$bvModal.hide('modal-update-enterprise')
      this.$bvModal.hide('modal-create-webhook')
      this.$bvModal.hide('modal-update-webhook')
      this.$bvModal.hide('modal-delete-webhook')
    },
  },
}
</script>
